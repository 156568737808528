<template>
    <div class="container">
        <el-row>
            <content-title :nav="nav"></content-title>
        </el-row>
        <el-descriptions title="课程信息" v-if="course.id">
    <template slot="extra">
        <el-button type="primary" size="small" @click="openEdit">修改信息</el-button>
    </template>
            <el-descriptions-item label="课程名称">
                {{ course.title  }}
                <el-tag size="small">{{ course.card_type=="优学卡"?"天赋课":"正式课"}}</el-tag>
                <el-tag size="small" v-if="course.is_recommend">优选</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="课程类型">
                {{ course.categories_child  }}
            </el-descriptions-item>
            <el-descriptions-item label="课程节数">
                {{ course.course_num  }}
            </el-descriptions-item>
            <el-descriptions-item label="售价">
                ￥{{ course.discount_amout  }}
            </el-descriptions-item>
            <el-descriptions-item label="可用消费金">
                ￥{{ course.consume  }}
            </el-descriptions-item>
            <el-descriptions-item label="单节结算价">
                ￥{{ course.settlement_cash  }}
            </el-descriptions-item>
            <el-descriptions-item label="已售数量">
                {{ course.pay_num  }}
            </el-descriptions-item>
            <el-descriptions-item label="状态">
                <div v-if="course.is_conceal === 0">
              <el-tag  size="small"  v-if="course.status == 4">待审核</el-tag>
              <el-tag size="small"  type="success" v-if="course.status == 2">已通过</el-tag>
              <el-tag  size="small" type="info" v-if="course.status == 1">审核拒绝</el-tag>
              <el-tag size="small"  type="warning" v-if="course.status == 5">下架</el-tag>
              <el-tag  size="small" type="danger" v-if="course.status == 6">冻结</el-tag>
            </div>
            <div v-else>
            <el-tag size="small" type="info">已隐藏</el-tag>

            </div>
            </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="课程详情" v-if="course.id">
            <el-descriptions-item label="机构名称">
               
                <link-to :to="{ name: 'mecDetails', query: { mecID: course.mechanism_id } }">
                {{ course.map.masterMechanismEntity.mechanism_name  }}
            </link-to>
            </el-descriptions-item>
            <el-descriptions-item label="机构地址">
                {{ course.map.masterMechanismEntity.mechanism_addr  }}
            </el-descriptions-item>
            <el-descriptions-item label="课程区域">
                {{ course.city  }}{{ course.district }}
            </el-descriptions-item>
            <el-descriptions-item label="排课方式">
                {{ course.appointment_type=="appointment" ? "预约排课":"固定排课"  }}
            </el-descriptions-item>
            <el-descriptions-item label="上课人数">
                {{ course.connect_peoplenum  }}人
            </el-descriptions-item>
            <el-descriptions-item label="适用人群">
                {{ course.school_age  }}
            </el-descriptions-item>
            <el-descriptions-item label="适用年龄">
                {{ course.apply_age  }}
            </el-descriptions-item>
            <el-descriptions-item label="课程时长">
                {{ course.length_of_lesson  }}分钟
            </el-descriptions-item>
            <el-descriptions-item label="课程详情">
                {{ course.introduction_content  }}
            </el-descriptions-item>
            <el-descriptions-item label="课程提示">
                {{ course.label  }}
            </el-descriptions-item>
            <el-descriptions-item label="正式课详情">
                {{ course.introduction_text  }}
            </el-descriptions-item>
            <el-descriptions-item label="天赋课详情">
                {{ course.introduction_talent  }}
            </el-descriptions-item>

            <el-descriptions-item label="课程封面">
                <el-image
                style="width: 150px; height: 150px"
                :src="course.face_url"
                fit="cover"
                :preview-src-list="[course.face_url]"
              ></el-image>
            </el-descriptions-item>

            <el-descriptions-item label="课程介绍">
                <el-image
                v-for="(pic,index) in course.introduction_url.split(',')"
                style="width: 150px; height: 150px"
                :src="pic"
                fit="cover"
                :preview-src-list="course.introduction_url.split(',')"
                :key="index"
              ></el-image>
            </el-descriptions-item>
        </el-descriptions>
        <addCourse mode="edit" :courseData="course" ref="course" @success="getCourseInfo"></addCourse>

    </div>
</template>

<script>
import addCourse from "@/views/courseManage/components/course/addCourse.vue";

export default {
  components: {
    addCourse,
  },

    data() {
        return {
            nav: { firstNav: "课程管理", secondNav: "课程详情" },
            id: "",
            course: {},
        };
    },

    mounted() {
        this.id = this.$route.params.id
        this.getCourseInfo()
    },

    methods: {
        openEdit(){
            this.$refs.course.open()
        },
        getCourseInfo() {
            this.$axios({
                url: "user/masterSetPrice/queryCommodityList",
                params: {
                    id: this.id
                }
            }).then(res => {
                this.course = res.data.data.list[0]
            })
        }
    },
};
</script>

<style lang="less" scoped></style>